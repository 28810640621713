

// jQuery
import $ from "jquery";
window.$ = $;

import './fonts';

// Libraries
import { gsap } from "gsap";
import 'focus-visible';

// Utils scripts
import { gsapAccordion } from './utils/accordions';
import { equalizerMultiple } from './utils/equalizer-multiple';
import { mainMenu, menuNavigation } from './utils/menu';
import { asciiArt } from './utils/ascii-art';
import { fontsLoad } from './fonts';
import { cookiesBanner } from './utils/cookies-banner';

window.addEventListener('DOMContentLoaded', () => {
    // fontsLoad();
    mainMenu(gsap);
    menuNavigation();
    asciiArt();
    gsapAccordion(gsap);
    cookiesBanner(gsap);
    // Run equalizer when fonts are loaded
    if (typeof document.fonts == 'undefined') {
        equalizerMultiple();
    } else {
        document.fonts.ready.then(function () {
            equalizerMultiple();
        })
    }
})

window.addEventListener('resize', () => {
    equalizerMultiple();
    mainMenu(gsap);
    menuNavigation();
})