const asciiArt = () => {
    const artStr = String.raw`
    "                       Developed By 🪄                       "
    "                                                             "
    "    ____  _            _      __  __               _         "    
    "   |  _ \| |          | |    |  \/  |             (_)        "
    "   | |_) | | __ _  ___| | __ | \  / | __ _   ___   _  ___    "
    "   |  _ <| |/ _  |/ __| |/ / | |\/| |/ _  | / _  || |/ --|   "
    "   | |_) | | (_| | (__|   <  | |  | | (_| || (_| || | (__    "
    "   |____/|_|\__,_|\___|_|\_\ |_|  |_|\__,_| \__, ||_|\___|   "
    "                                            __/ /            "
    "                                           |___/             " 
    "                                                             "
    "                     heyblackmagic.com                       "
    "     © 2022 Black Magic • Houston, Texas & Mexico City       "
    "          Making The Internet A Happier Place™               "
    `;
    
    console.log(artStr);
}


export { asciiArt };