const equalizerMultiple = () => {
    const equilizerMultiContainers = document.querySelectorAll('[data-alloyEqualize-multiple]');
    if (equilizerMultiContainers.length) {
        equilizerMultiContainers.forEach((eContainer) => {
            let limitItems = eContainer.dataset.alloyequalizeMultiple ? eContainer.dataset.alloyequalizeMultiple : 10;
            for (let i = 0; i <= limitItems; i++) {
                let equilizerItem = eContainer.querySelectorAll('[data-alloyequalize-watch-' + i + ']');
                if (equilizerItem.length) {
                    let itemHeightPrevious;
                    let itemHighest = 0;
                    equilizerItem.forEach((el) => {
                        el.style.removeProperty('height');
                        let itemHeight = getInnerHeight(el);
                        if (itemHeightPrevious) {
                            if (itemHeight > itemHighest) {
                                itemHighest = itemHeight;
                            }
                            itemHeightPrevious = itemHeight;
                        } else {
                            itemHeightPrevious = itemHeight;
                            itemHighest = itemHeight;
                        }
                    });
                    equalizeElements(equilizerItem, itemHighest);
                }
            }
        });
    }
}

function getInnerHeight(elm) {
    // var computed = getComputedStyle(elm),
    // padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
    // return elm.clientHeight - padding
    return elm.clientHeight
}

function equalizeElements(elements, height) {
    elements.forEach((el) => {
        el.style.height = height + "px";
    })
}

export { equalizerMultiple };