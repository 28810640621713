/* Cookie functionality */
const cookiesBanner = (gsap) => {
    const btnYes = document.querySelector(".cookies-yes");
    const btnNah = document.querySelector(".nah");
    if(btnYes){
        btnYes.addEventListener("click", function(e) {
            e.preventDefault();
            createCookie('accepted', 'true', 365);
            gsap.to('.cookies', {
                alpha: 0,
                zIndex: -10,
                ease: 'power3.out',
                display: "none"
            });
        });
    }
    if(btnNah){
        btnNah.addEventListener("click", function(e) {
            e.preventDefault();
            gsap.to('.cookies', {
                alpha: 0,
                zIndex: -10,
                ease: 'power3.out',
                display: "none"
            });
        });
    }

    const cookie = findCookie('accepted');
    if (!cookie) {
        gsap.to('.cookies', {
            alpha: 1,
            ease: 'power2.out',
            display: "block"
        });
    }
}

const createCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

const findCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

export { cookiesBanner };