import { ScrollTrigger } from 'gsap/ScrollTrigger';

const mainMenu = (gsap) => {
    gsap.registerPlugin(ScrollTrigger);
    // Handles the hamburger icon functionality.
    const hamburger = document.querySelector(".hamburger");
    if (hamburger) {
        const searchButton = document.querySelector('.search-button button');
        let target = document.querySelector(hamburger.dataset.menuTarget);
        if (target) {
            // Handles click on burger button
            hamburger.addEventListener("click", (e) => {
                e.stopImmediatePropagation();
                e.stopPropagation();
                if (hamburger.classList.contains('is-active')) {
                    hamburgerRemoveClass(hamburger);
                    toggleBodyOverflow("visible");
                    gsap.to(target, {
                        right: (target.offsetWidth * -1),
                        duration: 0.5
                    });
                    gsap.to(searchButton, {
                        autoAlpha: 1,
                        duration: 0.2,
                        onComplete: () => {
                            searchButton.removeAttribute("disabled", "");
                        }
                    })
                } else {
                    gsap.to(target, {
                        right: 0,
                        duration: 0.5
                    });
                    animateMenuItems(target, gsap);
                    hamburgerAddClass(hamburger);
                    toggleBodyOverflow("hidden");
                    gsap.to(searchButton, {
                        autoAlpha: 0,
                        duration: 0.2,
                        onComplete: () => {
                            searchButton.setAttribute("disabled", "");
                        }
                    })
                }
            });

            // Click anywhere to close menu if opened
            // window.addEventListener("click", (e) => {
            //     if (hamburger.classList.contains('is-active')) {
            //         hamburgerRemoveClass(hamburger);
            //         toggleBodyOverflow("visible");
            //         gsap.to(target, {
            //             right: (target.offsetWidth * -1),
            //             duration: 0.5
            //         });
            //     }
            // });
            // target.addEventListener("click", (e) => {
            //     e.stopPropagation();
            // });

            // Responds to ESC Key to close menu.
            window.addEventListener('keydown', function (e) {
                if (e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27) {
                    // e.stopImmediatePropagation();
                    // e.stopPropagation();
                    if (hamburger.classList.contains('is-active')) {
                        hamburgerRemoveClass(hamburger);
                        toggleBodyOverflow("visible");
                        gsap.to(target, {
                            right: (target.offsetWidth * -1),
                            duration: 0.5
                        });
                        gsap.to(searchButton, {
                            autoAlpha: 1,
                            duration: 0.2,
                            onComplete: () => {
                                searchButton.removeAttribute("disabled", "");
                            }
                        })
                    }
                }
            }, true);

            // Responds to swipe right on the menu
            // let touchstartX = 0;
            // let touchstartY = 0;
            // let touchendX = 0;
            // let touchendY = 0;

            // target.addEventListener('touchstart', function (event) {
            //     touchstartX = event.changedTouches[0].screenX;
            //     touchstartY = event.changedTouches[0].screenY;
            // }, false);

            // target.addEventListener('touchend', function (event) {
            //     touchendX = event.changedTouches[0].screenX;
            //     touchendY = event.changedTouches[0].screenY;
            //     let swipedRight = handleGesture(touchendX, touchstartX, touchendY, touchstartY);
            //     if (swipedRight) {
            //         hamburgerRemoveClass(hamburger);
            //         toggleBodyOverflow("visible");
            //         gsap.to(target, {
            //             right: (target.offsetWidth * -1),
            //             duration: 0.5
            //         });
            //         gsap.to(searchButton, {
            //             autoAlpha: 1,
            //             duration: 0.2,
            //             onComplete: () => {
            //                 searchButton.removeAttribute("disabled", "");
            //             }
            //         })
            //     }
            // }, false);

        }
    }

    const searchTrigger = document.querySelector('[data-search-target]');
    if (searchTrigger) {
        let searchTarget = document.querySelector(searchTrigger.dataset.searchTarget);
        if (searchTarget) {
            searchTrigger.addEventListener("click", (e) => {
                e.stopImmediatePropagation();
                e.stopPropagation();
                if (searchTrigger.classList.contains('is-active')) {
                    toggleBodyOverflow("visible");
                    gsap.to(searchTarget, {
                        top: (searchTarget.offsetHeight * -1),
                        duration: 0.5
                    });
                    searchTrigger.classList.remove('is-active');
                    gsap.to(hamburger, {
                        autoAlpha: 1,
                        duration: 0.2,
                        onComplete: () => {
                            hamburger.removeAttribute("disabled", "");
                        }
                    });
                } else {
                    gsap.to(searchTarget, {
                        top: '5.5rem',
                        duration: 0.5
                    });
                    toggleBodyOverflow("hidden");
                    searchTrigger.classList.add('is-active');
                    gsap.to(hamburger, {
                        autoAlpha: 0,
                        duration: 0.2,
                        onComplete: () => {
                            hamburger.setAttribute("disabled", "");
                        }
                    })
                }
            });
        }
    }

    // Header Home animation (hide/show) on scroll.
    const headerHome = document.querySelector('header.header');
    if (headerHome) {
        const deviceType = getDevice();
        const headerInner = headerHome.querySelector('.main-header-inner');
        if (deviceType === 'desktop') {
            const parkingTab = document.querySelector('.parking-tab');
            ScrollTrigger.create({
                start: "800px top",
                onEnter: () => {
                    headerHome.classList.add('collapsed')
                    parkingTab.classList.add('collapsed')
                    gsap.to(headerInner, {
                        top: 0,
                        duration: 0.4,
                        delay: 0.2
                    })
                },
                onLeaveBack: () => {
                    gsap.to(headerInner, {
                        top: '-70px',
                        duration: 0.4,
                        onComplete: () => {
                            headerHome.classList.remove('collapsed')
                            parkingTab.classList.remove('collapsed')
                        }
                    })
                },
            });
        }
    }
}

// Swipe right: (touchendX > touchstartX)
// Swipe left:  (touchendX < touchstartX)
// Swipe up:    (touchendY < touchstartY)
// Swipe down:  (touchendY > touchstartY)
// Tap:         (touchendY === touchstartY)
const handleGesture = (touchendX, touchstartX, touchendY, touchstartY) => {
    if (touchendX > touchstartX) {
        return true;
    }
    return false;
}

// Remove class in all hamburgers
const hamburgerRemoveClass = (hamburger) => {
    hamburger.classList.remove("is-active");
}

// Add class in all hamburgers
const hamburgerAddClass = (hamburger) => {
    hamburger.classList.add("is-active");
}

const animateMenuItems = (target, gsap) => {
    const popUpLinks = target.querySelectorAll('nav > ul > li.fade');
    gsap.from(popUpLinks, {
        alpha: 0,
        x: 50,
        stagger: 0.1,
        duration: 0.5,
        delay: 0.2
    })
}

const toggleBodyOverflow = (status) => {
    const deviceType = getDevice();
    if (deviceType != "desktop") {
        document.body.style.overflow = status;
    }
}

/**
 * Get Device Type function
 * @returns 
 */
const getDevice = () => {
    const ua = navigator.userAgent;
    if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
        (window.matchMedia('only screen and (min-width: 768px)').matches &&
            window.matchMedia('only screen and (max-width: 1279px)').matches)
    ) {
        return 'tablet';
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua,
        ) ||
        window.matchMedia('only screen and (max-width: 767px)').matches
    ) {
        return 'mobile';
    }
    return 'desktop';
};

const menuNavigation = () => {
    const deviceType = getDevice();
    if (deviceType === 'desktop') {        
        const header = document.querySelector('header.header');
        const listItems = header.querySelectorAll('.menu-items-wrapper nav > ul > li');
        if(listItems.length > 0){
            const listButtons = header.querySelectorAll('.menu-items-wrapper nav > ul > li .cta-expandible button');
            const logo = header.querySelector('.logo-wrapper a');
    
            const searchButton = header.querySelector('.search-form button');
            searchButton.addEventListener('focusout',() => {
                listButtons.forEach((buttonItem) => {
                    buttonItem.classList.remove('is-visible');
                })
                listItems.forEach((list) => {
                    list.classList.remove('expanded');
                })
            })

            logo.addEventListener('focus',() => {
                listButtons.forEach((buttonItem) => {
                    buttonItem.classList.remove('is-visible');
                })
                listItems.forEach((list) => {
                    list.classList.remove('expanded');
                })
            })
            listItems.forEach((listItem, index) => {
                const anchor = listItem.querySelector('.cta-expandible a');
                const button = listItem.querySelector('.cta-expandible button');
                if(anchor && button){
                    anchor.addEventListener('focus', (e) => {
                        if(typeof anchor.dataset.focusVisibleAdded === 'string') {
                            listButtons.forEach((buttonItem) => {
                                buttonItem.classList.remove('is-visible');
                            })
                            button.classList.add('is-visible');    
                            listItems.forEach((list) => {
                                list.classList.remove('expanded');
                            })
                        }
                    })
                    button.addEventListener('focusout',() => {
                        button.classList.remove('is-visible');
                    })
                    button.addEventListener('keypress',(e) => {
                        if(e.key === 'Enter'){
                            if(listItem.classList.contains('expanded')){
                                listItem.classList.remove('expanded');
                            } else {
                                listItem.classList.add('expanded');
                            }
                        }
                    })
                }
            })
            document.addEventListener('keydown', function(event){
                if(event.key === "Escape"){
                    listItems.forEach((list) => {
                        list.classList.remove('expanded');
                    })
                }
            });
        } 
    }
}

export { mainMenu, menuNavigation };